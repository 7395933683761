<template>
    <b-row>
        <b-col cols="12" class="mb-32">
            <page-title />
        </b-col>
        <div class="magazine-container">
            <b-card>
                <div class="breadcrumb-custom">
                    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
                </div>
                <b-form>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="ชื่อ (Eng)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="firstname_eng" placeholder="กรอกชื่อภาษาอังกฤษ"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="นามสกุล (Eng)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="lastname_eng" placeholder="กรอกนามสกุลภาษาอังกฤษ"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="ชื่อ (Th)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="firstname_th" placeholder="กรอกชื่อภาษาไทย"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2" label="นามสกุล (Th)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="lastname_th" placeholder="กรอกนามสกุลภาษาไทย"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2" label="สังกัด (Eng)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="affiliation_eng" placeholder="กรอกสังกัดภาษาอังกฤษ"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2" label="สังกัด (Th)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="affiliation_th"
                                    placeholder="กรอกสังกัดภาษาไทย"></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="อีเมล"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="email" placeholder="กรอกอีเมล"></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="เบอร์โทรศัพท์"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="number_phone"
                                    placeholder="กรอกเบอร์โทรศัพท์"></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="ที่อยู่"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="address" placeholder="กรอกที่อยู่"></b-form-input>
                            </b-form-group></div>
                    </div>
                    <div class="submit-container">
                        <b-button variant="outline-black-100" v-on:click=(updateAuthor())>บันทึก</b-button>
                    </div>
                </b-form>


            </b-card>
        </div>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BButton, BForm, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BFormSelect, BFormInput, BAlert, BSpinner, BModal, BBreadcrumb } from "bootstrap-vue";
import { validEmail } from "../../../../../helpers/validation";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import authorService from "../../../../../services/author.service";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BAlert,
        BSpinner,
        BModal,
        BBreadcrumb,
        PageTitle
    },
    data() {
        return {
            authorId: null,
            firstname_eng: null,
            lastname_eng: null,
            firstname_th: null,
            lastname_th: null,
            affiliation_eng: null,
            affiliation_th: null,
            email: null,
            number_phone: null,
            address: null,
            show: true,
            isLoading: false,
            errors: [],
            breadcrumbItems: [
                {
                    text: "การตั้งค่า",
                    href: "#",
                },
                {
                    text: "ผู้เขียน",
                    to: { name: "authors" },
                },
                {
                    text: "แก้ไขผู้เขียน",
                    active: true,
                },
            ],
        };
    },

    methods: {
        getAuthor: async function () {
            this.authorId = this.$route.params.id;
            let response = await authorService.getAuthorById(this.authorId);
            let data = response.data.authors;
            this.firstname_eng = data.firstname_eng;
            this.lastname_eng = data.lastname_eng;
            this.firstname_th = data.firstname_th;
            this.lastname_th = data.lastname_th;
            this.affiliation_eng = data.affiliation_eng;
            this.affiliation_th = data.affiliation_th;
            this.email = data.email;
            this.number_phone = data.number_phone;
            this.address = data.address;

        },

        updateAuthor: async function () {
            try {
                this.isLoading = true;
                if (this.isValidate()) {
                    let newR = {
                        id: this.authorId,
                        firstname_eng: this.firstname_eng,
                        lastname_eng: this.lastname_eng,
                        firstname_th: this.firstname_th,
                        lastname_th: this.lastname_th,
                        affiliation_eng: this.affiliation_eng,
                        affiliation_th: this.affiliation_th,
                        email: this.email,
                        number_phone: this.number_phone,
                        address: this.address
                    }
                    await authorService.updateAuthor(newR);
                    this.isLoading = false;
                    setTimeout(() => { this.isSaved = false; }, 3000)
                } else {
                    this.isLoading = false;
                    this.$bvModal
                        .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                            cancelVariant: "text",
                            okTitle: "ตกลง",
                            size: "sm",
                        })
                        .then((value) => {
                            this.boxOne = value;
                        })
                }


            } catch (error) {
                this.$bvModal
                    .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                        cancelVariant: "text",
                        okTitle: "ตกลง",
                        size: "sm",
                    })
                    .then((value) => {
                        this.boxOne = value;
                    })
            }


        },
        isValidate() {
            this.errors = [];
            if (!this.firstname_eng) this.errors.push("Firstname Eng required.");
            if (!this.lastname_eng) this.errors.push("Lastname Eng required.");
            if (!this.firstname_th) this.errors.push("Firstname Th required.");
            if (!this.lastname_th) this.errors.push("Lastname Th required.");
            if (!this.affiliation_eng) this.errors.push("Affiliation Eng required.");
            if (!this.affiliation_th) this.errors.push("Affiliation Th required.");
            if (!validEmail(this.email) || !this.email) this.errors.push("email required.");

            if (!this.errors.length) {
                return true;
            }
            return false;
        },

    },
    mounted() {
        this.getAuthor();
    }

};
</script>